import {
  CLIENT_CONSTANTS,
  FleetConfigurationClientProvider,
  type FleetConfigurationClientProviderConfig,
} from "@fleet-configuration/client";
import { Environment, getEnv } from "@fleet-configuration/utils";
import React from "react";

export type SmartComponentWrapperProps = {
  getAccessTokenSilently: () => Promise<string>;
  children: React.ReactNode;
};

const fleetConfigurationClientProviderConfig: FleetConfigurationClientProviderConfig =
  {
    environment: getEnv() ?? Environment.Development,
    endpoint: CLIENT_CONSTANTS.FLEET_CONFIGURATION_GRAPHQL_PATH,
    notificationsEndpoint:
      CLIENT_CONSTANTS.FLEET_CONFIGURATION_GRAPHQL_NOTIFICATIONS_PATH,
  };

export const SmartComponentWrapper = ({
  getAccessTokenSilently,
  children,
}: SmartComponentWrapperProps) => {
  return (
    <FleetConfigurationClientProvider
      config={fleetConfigurationClientProviderConfig}
      getAccessTokenSilently={getAccessTokenSilently}
    >
      {children}
    </FleetConfigurationClientProvider>
  );
};
