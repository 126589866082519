import { ApolloProvider } from "@apollo/client";
import React from "react";

import { ApolloClientConfig } from "./getApolloClient";
import { getApolloClientSingleton } from "./getApolloClientSingleton";

export type ApolloWrapperProps = {
  children: React.ReactNode;
  config: ApolloClientConfig;
  getAccessTokenSilently: () => Promise<string>;
};

export const ApolloWrapper = (props: ApolloWrapperProps) => {
  const apolloClient = getApolloClientSingleton({
    config: props.config,
    getAccessTokenSilently: props.getAccessTokenSilently,
  });

  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};
