import { useCallback, useEffect, useState } from "react";

import {
  type NodePayload,
  type TreePickerNode,
} from "../../components/tree-picker/types";
import { getAllNodeIds } from "../../components/tree-picker/utils";

export type UseTreePickerExpandCollapseProps = {
  data: TreePickerNode[];
  activeQuery?: string;
  toggleExpandCollapseAll?: boolean;
};

export const useTreePickerExpandCollapse = ({
  data,
  activeQuery,
  toggleExpandCollapseAll,
}: UseTreePickerExpandCollapseProps) => {
  const [expandedNodes, setExpandedNodes] = useState<TreePickerNode["id"][]>(
    [],
  );

  useEffect(() => {
    // expand/collapse all nodes when toggleExpandCollapseAll or activeQuery prop changes
    if (toggleExpandCollapseAll || activeQuery) {
      const allNodeIds = data.flatMap((node) => getAllNodeIds(node));
      setExpandedNodes(allNodeIds);
    } else {
      setExpandedNodes([]);
    }
  }, [toggleExpandCollapseAll, data, activeQuery]);

  const handleNodeExpand = useCallback(
    (payload: NodePayload) => {
      if (!expandedNodes.includes(payload.node.id)) {
        setExpandedNodes((prev) => [...prev, payload.node.id]);
      }
    },
    [expandedNodes],
  );

  const handleNodeCollapse = useCallback(
    (payload: NodePayload) => {
      if (expandedNodes.includes(payload.node.id)) {
        setExpandedNodes((prev) => prev.filter((id) => id !== payload.node.id));
      }
    },
    [expandedNodes],
  );

  return {
    expandedNodes,
    handleNodeExpand,
    handleNodeCollapse,
  };
};
