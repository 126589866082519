import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";

import { useTreePickerExpandCollapse } from "../../../../hooks/useTreePickerExpandCollapse";
import { useTreePickerFilteredNodes } from "../../../../hooks/useTreePickerFilteredNodes";
import { Node } from "../../node";
import {
  type NodePayload,
  NodeSelectionType,
  type TreePickerNode,
} from "../../types";
import { isNodeSelectable } from "../../utils";
import { NoResults } from "../no-results";

export type TreePickerLeafSelectProps = {
  activeQuery?: string;
  data: TreePickerNode[];
  nodeColor?: string;
  onChange?: (payload: TreePickerNode["id"][]) => void;
  selectedNodeId?: TreePickerNode["id"];
  style?: React.CSSProperties;
  toggleExpandCollapseAll?: boolean;
};

export const TreePickerLeafSelect = ({
  activeQuery,
  data,
  nodeColor,
  onChange,
  selectedNodeId,
  style,
  toggleExpandCollapseAll,
}: TreePickerLeafSelectProps) => {
  const [selectedNodes, setSelectedNodes] = useState<
    TreePickerNode["id"] | null
  >();

  const filteredData = useTreePickerFilteredNodes({ activeQuery, data });

  const { expandedNodes, handleNodeExpand, handleNodeCollapse } =
    useTreePickerExpandCollapse({
      data: filteredData,
      activeQuery,
      toggleExpandCollapseAll,
    });

  /**
   * Node Selection
   */

  useEffect(() => {
    // set selected nodes when selectedNodeId prop changes
    if (selectedNodeId) {
      setSelectedNodes(() => {
        onChange?.([selectedNodeId]);
        return selectedNodeId;
      });
    }
  }, [onChange, selectedNodeId]);

  const handleNodeSelect = useCallback(
    (payload: NodePayload) => {
      setSelectedNodes(() => {
        const newSelectedNodes = payload.node.id;
        onChange?.([newSelectedNodes]);
        return newSelectedNodes;
      });
    },
    [onChange],
  );

  const handleNodeDeselect = useCallback(() => {
    setSelectedNodes(() => {
      onChange?.([]);
      return null;
    });
  }, [onChange]);

  /**
   * Renderers
   */

  const renderNodes = (nodes: TreePickerNode[], level: number) => {
    return nodes.map((node) => {
      return (
        <div key={node.id}>
          <Node
            activeQuery={activeQuery}
            displayNodeSelection={true}
            hasSelectedDescendants={false}
            isExpanded={expandedNodes.includes(node.id)}
            isSelectable={isNodeSelectable(node, false)}
            level={level}
            node={node}
            nodeColor={nodeColor}
            onCollapse={handleNodeCollapse}
            onDeselect={handleNodeDeselect}
            onExpand={handleNodeExpand}
            onSelect={handleNodeSelect}
            selected={selectedNodes === node.id}
            selectionType={NodeSelectionType.SingleLeafOnly}
          />
          <AnimatePresence>
            {expandedNodes.includes(node.id) && node.children.length > 0 && (
              <motion.div
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0, overflow: "hidden" }}
                initial={{ opacity: 0, height: 0, overflow: "hidden" }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                {renderNodes(node.children, level + 1)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      );
    });
  };

  return (
    <div data-testid="treepicker" style={style}>
      {filteredData.length === 0 ? (
        <NoResults activeQuery={activeQuery} />
      ) : (
        renderNodes(filteredData, 0)
      )}
    </div>
  );
};
