import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useReducer, useRef } from "react";

import { treePickerReducer } from "./treePickerMultiSelect.reducer";

import { useTreePickerExpandCollapse } from "../../../../hooks/useTreePickerExpandCollapse";
import { useTreePickerFilteredNodes } from "../../../../hooks/useTreePickerFilteredNodes";
import { Node } from "../../node";
import {
  type NodePayload,
  NodeSelectionType,
  TreePickerActionType,
  type TreePickerNode,
} from "../../types";
import { buildNodeMap, isNodeSelectable, isNodeWithCount } from "../../utils";
import { NoResults } from "../no-results/NoResults";

export type TreePickerMultiSelectProps = {
  activeQuery?: string;
  data: TreePickerNode[];
  onChange?: (payload: TreePickerNode["id"][]) => void;
  selectedNodeIds?: TreePickerNode["id"][];
  style?: React.CSSProperties;
  toggleExpandCollapseAll?: boolean;
};

export const TreePickerMultiSelect = ({
  activeQuery,
  data,
  onChange,
  selectedNodeIds,
  style,
  toggleExpandCollapseAll,
}: TreePickerMultiSelectProps) => {
  const hasMounted = useRef(false);

  const filteredData = useTreePickerFilteredNodes({ activeQuery, data });

  const { expandedNodes, handleNodeExpand, handleNodeCollapse } =
    useTreePickerExpandCollapse({
      data: filteredData,
      activeQuery,
      toggleExpandCollapseAll,
    });

  // Build a node map for quick lookup
  const nodeMap = buildNodeMap(filteredData);

  const [state, dispatch] = useReducer(treePickerReducer, {
    selectedNodeIds: selectedNodeIds || [],
    nodeStates: {},
  });

  /**
   * Node Selection
   */

  useEffect(() => {
    // Update selected nodes when selectedNodeIds prop changes
    if (selectedNodeIds && selectedNodeIds.length > 0) {
      selectedNodeIds.forEach((nodeId) => {
        const node = nodeMap[nodeId];
        if (node) {
          dispatch({
            type: TreePickerActionType.SelectNode,
            payload: { node, nodeMap, selected: true },
          });
        }
      });
    }
  }, [nodeMap, selectedNodeIds]);

  useEffect(() => {
    // Ensure that the onChange callback is called when nodes are selected
    if (hasMounted.current) {
      onChange?.(state.selectedNodeIds || []);
    } else {
      hasMounted.current = true;
      return;
    }
  }, [onChange, state.selectedNodeIds]);

  const handleNodeSelect = useCallback(
    (payload: NodePayload) => {
      dispatch({
        type: TreePickerActionType.SelectNode,
        payload: { ...payload, nodeMap },
      });
    },
    [nodeMap],
  );

  const handleNodeDeselect = useCallback(
    (payload: NodePayload) => {
      dispatch({
        type: TreePickerActionType.DeselectNode,
        payload: { ...payload, nodeMap },
      });
    },
    [nodeMap],
  );

  /**
   * Renderers
   */

  // Recursively render nodes
  const renderNodes = (nodes: TreePickerNode[], level: number) => {
    return nodes.map((node) => {
      const nodeState = state.nodeStates[node.id] || {
        selected: false,
        hasSelectedDescendants: false,
      };

      return (
        <div key={node.id}>
          <Node
            activeQuery={activeQuery}
            count={isNodeWithCount(node) ? node.count : undefined}
            displayNodeSelection={true}
            hasSelectedDescendants={nodeState.hasSelectedDescendants}
            isExpanded={expandedNodes.includes(node.id)}
            isSelectable={isNodeSelectable(node, true)}
            level={level}
            node={node}
            onCollapse={handleNodeCollapse}
            onDeselect={handleNodeDeselect}
            onExpand={handleNodeExpand}
            onSelect={handleNodeSelect}
            selected={nodeState.selected}
            selectionType={NodeSelectionType.Multi}
          />
          <AnimatePresence>
            {expandedNodes.includes(node.id) && node.children.length > 0 && (
              <motion.div
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0, overflow: "hidden" }}
                initial={{ opacity: 0, height: 0, overflow: "hidden" }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                {renderNodes(node.children, level + 1)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      );
    });
  };

  return (
    <div data-testid="treepicker" style={style}>
      {filteredData.length === 0 ? (
        <NoResults activeQuery={activeQuery} />
      ) : (
        renderNodes(filteredData, 0)
      )}
    </div>
  );
};
