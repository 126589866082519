import {
  EuiButtonEmpty,
  EuiFilterButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPopover,
  EuiPopoverTitle,
  EuiSelectable,
  EuiSelectableOption,
} from "@equipmentshare/ds2";
import { CloseCircleFillIcon } from "@equipmentshare/ds2-icons";
import { useState } from "react";

export type FilterButtonProps = {
  label: string;
  searchPlaceholder?: string;
  filterOptions: EuiSelectableOption[];
  onClearAll?: () => void;
  onClose?: (selectedOptions: EuiSelectableOption[]) => void;
  onChange?: (selectedOptions: EuiSelectableOption[]) => void;
};

export const FilterButton = ({
  label,
  onChange,
  onClearAll,
  onClose,
  filterOptions,
  searchPlaceholder,
}: FilterButtonProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [filters, setFilters] = useState<EuiSelectableOption[]>(filterOptions);

  const onFilterButtonClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const onClosePopover = () => {
    onClose && onClose(filters);
    setIsPopoverOpen(false);
  };

  const onClear = () => {
    setFilters(
      filters.map((item) => ({
        ...item,
        checked: undefined,
      })),
    );
    onClearAll && onClearAll();
  };

  const activeFilterCount = filters.filter(
    (item) => item.checked === "on",
  ).length;

  const button = (
    <EuiFilterButton
      data-testid={`${label.toLowerCase()}-filter`}
      hasActiveFilters={activeFilterCount > 0}
      iconType="arrowDown"
      isSelected={isPopoverOpen}
      numActiveFilters={activeFilterCount}
      numFilters={filters.length}
      onClick={onFilterButtonClick}
    >
      {label}
    </EuiFilterButton>
  );

  return (
    <EuiPopover
      button={button}
      closePopover={onClosePopover}
      isOpen={isPopoverOpen}
      panelPaddingSize="none"
    >
      <EuiPopoverTitle paddingSize="s">{label}</EuiPopoverTitle>
      <EuiSelectable
        aria-label={label}
        noMatchesMessage={`No matching ${label}`}
        onChange={(allFilters) => {
          onChange && onChange(allFilters);
          setFilters(allFilters);
        }}
        options={filters}
        searchProps={{
          onChange: (value, matches) => {},
          placeholder: searchPlaceholder ?? "Search",
        }}
        searchable
        singleSelection={false}
      >
        {(list, search) => {
          return (
            <div style={{ width: 300 }}>
              <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
              {list}
              {onClearAll && (
                <EuiFlexGroup direction="column" gutterSize="none">
                  <EuiHorizontalRule margin="none" />
                  <EuiFlexItem>
                    <EuiButtonEmpty
                      color="danger"
                      iconType={CloseCircleFillIcon}
                      onClick={onClear}
                    >
                      Clear all
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
            </div>
          );
        }}
      </EuiSelectable>
    </EuiPopover>
  );
};
