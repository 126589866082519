import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiLink,
  EuiSelectableOption,
  EuiSpacer,
  EuiText,
} from "@equipmentshare/ds2";
import {
  PimProduct,
  ProductFilterState,
  useFilters,
  useSearchPimProducts,
} from "@fleet-configuration/client";
import { Fragment, useCallback, useState } from "react";

import { ProductFilters } from "./product-filters";
import { SearchResult, SelectedProduct } from "./products";

import { FormFieldRow } from "../../form-fields/form-field-row";
import { AddProductFlyout } from "../add-product-flyout";

export type MatchProductProps = {
  onAddProduct: () => void;
  initialSelectedProduct?: PimProduct;
  errorMessage?: string;
};

export type MatchProductSelectorProps = {
  products: PimProduct[];
  hasSelectedFilters?: boolean;
  onSearch: (searchTerm: string) => void;
  searchTerm?: string;
  onSelectProduct: (product: PimProduct) => void;
  onFilterChange: (
    filterType: keyof ProductFilterState,
    filterValue: string[],
  ) => void;
} & MatchProductProps;

export const MatchProduct = ({
  onAddProduct,
  initialSelectedProduct,
  errorMessage,
}: MatchProductProps) => {
  const { fetchMore } = useSearchPimProducts();
  const { data: filters } = useFilters();

  const [products, setProducts] = useState<PimProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<
    PimProduct | undefined
  >(initialSelectedProduct);

  const [isAddProductFlyoutVisible, setIsAddProductFlyoutVisible] =
    useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<ProductFilterState>({
    categories: [],
    manufacturers: [],
    models: [],
    years: [],
  });

  const checkHasSelectedFilters = useCallback(() => {
    return Object.values(selectedFilters).some((filter) => filter.length > 0);
  }, [selectedFilters]);

  const onAddNewProduct = () => {
    onAddProduct();
    setIsAddProductFlyoutVisible(true);
  };

  const onSearch = useCallback(
    async (search: string) => {
      const hasSelectedFilters = checkHasSelectedFilters();
      setSearchTerm(search);
      if (!search && !hasSelectedFilters) {
        setProducts([]);
        return;
      }

      const products = await fetchMore(search, selectedFilters);
      setProducts(products);
    },
    [fetchMore, selectedFilters, checkHasSelectedFilters],
  );

  const updateFilters = async (
    filterType: keyof ProductFilterState,
    filterValue: string[],
  ) => {
    if (selectedFilters[filterType].length === 0 && filterValue.length === 0) {
      return;
    }

    const updatedFilters = {
      ...selectedFilters,
      [filterType]: filterValue,
    };

    setSelectedFilters(updatedFilters);
    const products = await fetchMore(searchTerm, updatedFilters);
    setProducts(products);
  };

  const onEdit = (product: PimProduct) => {
    setSearchTerm(product?.name ?? "");
    setSelectedProduct(undefined);
  };

  const onChangeFilter =
    (filterType: keyof ProductFilterState) =>
    (options: EuiSelectableOption[]) => {
      updateFilters(
        filterType,
        options
          .filter((filter) => !!filter.checked)
          .map((filter) => filter.label),
      );
    };

  const onClearFilter = (filterType: keyof ProductFilterState) => {
    updateFilters(filterType, []);
  };

  const hasInitiatedSearch = searchTerm.length > 0 || checkHasSelectedFilters();
  const hasSelectedProduct = !!selectedProduct && !searchTerm;

  return (
    <>
      <FormFieldRow errorMessage={errorMessage} isRequired label="Product">
        {hasSelectedProduct ? (
          <SelectedProduct onEdit={onEdit} selectedProduct={selectedProduct} />
        ) : (
          <>
            <EuiText color="subdued" size="s">
              <p>
                Search &amp; connect a product to the asset. The more specific,
                the better the product match.
              </p>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiFlexGroup gutterSize="s">
              <EuiFieldSearch
                data-testid="product-search-input"
                isInvalid={!!errorMessage}
                onChange={(e) => {
                  onSearch(e.target.value);
                  setSearchTerm(e.target.value);
                }}
                placeholder="Example: Ford F-150 King Ranch"
                value={searchTerm}
              />
              <ProductFilters
                filters={filters}
                onChangeFilter={onChangeFilter}
                onClearFilter={onClearFilter}
              />
            </EuiFlexGroup>
            {errorMessage && (
              <EuiText color="danger" data-testid="error-message" size="s">
                {errorMessage}
              </EuiText>
            )}

            {hasInitiatedSearch && ( // TODO: once data is coming from api and not hardcoded, maybe switch to products.length > 0
              <>
                <EuiSpacer size="s" />
                <EuiText data-testid="product-count" size="m">
                  <strong>Products found:</strong>
                  {` ${products.length}`}
                </EuiText>
              </>
            )}

            {products.map((product) => {
              return (
                <Fragment key={product.id}>
                  <SearchResult
                    onSelect={() => {
                      setSelectedProduct(product);
                      setSearchTerm("");
                    }}
                    product={product}
                    searchTerm={searchTerm}
                  />
                </Fragment>
              );
            })}

            {hasInitiatedSearch && ( // Only show add new product cta if user has attempted to find a matching product
              <>
                <EuiSpacer size="m" />
                <EuiLink onClick={onAddNewProduct}>Add new product</EuiLink>
              </>
            )}
          </>
        )}
      </FormFieldRow>
      <AddProductFlyout
        isVisible={isAddProductFlyoutVisible}
        onAddProduct={() => {
          setIsAddProductFlyoutVisible(false);
          setSelectedProduct(undefined);
        }}
        onCancel={() => setIsAddProductFlyoutVisible(false)}
      />
    </>
  );
};
