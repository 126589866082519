/**
 * List of errors that should be discarded prior to sending to Datadog RUM
 *
 * @see https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#discard-a-frontend-error
 */

export const DATADOG_DISCARDED_ERRORS_STAGING = [
  "An asset with this name already exists",
  "Network Error",
] as const;

export const DATADOG_DISCARDED_ERRORS = [
  ...DATADOG_DISCARDED_ERRORS_STAGING,
  "ResizeObserver loop completed with undelivered notifications",
  "Failed to fetch",
] as const;
