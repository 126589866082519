import { useMeQuery } from "@fleet-configuration/client";

import { GetMe } from "./getMe";

import { SmartComponentWrapper } from "../smart-component-wrapper";

const GetMeWithQuery = () => {
  const { data, loading, error } = useMeQuery();
  return <GetMe data={data} error={error} loading={loading} />;
};

export const GetMeSmart = ({
  getAccessTokenSilently,
}: {
  getAccessTokenSilently: () => Promise<string>;
}) => {
  return (
    <SmartComponentWrapper getAccessTokenSilently={getAccessTokenSilently}>
      <GetMeWithQuery />
    </SmartComponentWrapper>
  );
};
