export type ProductFilterState = {
  categories: string[];
  manufacturers: string[];
  models: string[];
  years: string[];
};

type Filter = {
  id: string;
  name: string;
};

export type ApiFilters = {
  categories: Filter[];
  manufacturers: Filter[];
  models: Filter[];
  years: Filter[];
};

const mockFilters = {
  categories: [
    {
      id: "Category 1",
      name: "Category 1",
    },
    {
      id: "Category 2",
      name: "Category 2",
    },
    {
      id: "Category 3",
      name: "Category 3",
    },
    {
      id: "Category 4",
      name: "Category 4",
    },
    {
      id: "Category 5",
      name: "Category 5",
    },
    {
      id: "Category 6",
      name: "Category 6",
    },
    {
      id: "Category 7",
      name: "Category 7",
    },
    {
      id: "Category 8",
      name: "Category 8",
    },
    {
      id: "Category 9",
      name: "Category 9",
    },
    {
      id: "Category 10",
      name: "Category 10",
    },
  ],
  manufacturers: [
    {
      id: "Make 1",
      name: "Make 1",
    },
    {
      id: "Make 2",
      name: "Make 2",
    },
    {
      id: "Make 3",
      name: "Make 3",
    },
    {
      id: "Make 4",
      name: "Make 4",
    },
    {
      id: "Make 5",
      name: "Make 5",
    },
    {
      id: "Make 6",
      name: "Make 6",
    },
    {
      id: "Make 7",
      name: "Make 7",
    },
    {
      id: "Make 8",
      name: "Make 8",
    },
    {
      id: "Make 9",
      name: "Make 9",
    },
    {
      id: "Make 10",
      name: "Make 10",
    },
  ],
  models: [
    {
      id: "Model A",
      name: "Model A",
    },
    {
      id: "Model B",
      name: "Model B",
    },
    {
      id: "Model C",
      name: "Model C",
    },
    {
      id: "Model D",
      name: "Model D",
    },
    {
      id: "Model E",
      name: "Model E",
    },
    {
      id: "Model F",
      name: "Model F",
    },
    {
      id: "Model G",
      name: "Model G",
    },
    {
      id: "Model H",
      name: "Model H",
    },
    {
      id: "Model I",
      name: "Model I",
    },
    {
      id: "Model J",
      name: "Model J",
    },
  ],
  years: [
    {
      id: "2013",
      name: "2013",
    },
    {
      id: "2014",
      name: "2014",
    },
    {
      id: "2015",
      name: "2015",
    },
    {
      id: "2016",
      name: "2016",
    },
    {
      id: "2017",
      name: "2017",
    },
    {
      id: "2018",
      name: "2018",
    },
    {
      id: "2019",
      name: "2019",
    },
    {
      id: "2020",
      name: "2020",
    },
    {
      id: "2021",
      name: "2021",
    },
    {
      id: "2022",
      name: "2022",
    },
  ],
};

export const useFilters = () => {
  return {
    data: mockFilters,
    fetchMore: (search: string, filters: ProductFilterState) => {
      return Promise.resolve(mockFilters);
    },
  };
};

export const useSearchPimProducts = () => {
  return {
    data: mockData,
    fetchMore: (search: string, filters: ProductFilterState) => {
      return Promise.resolve(
        mockData.filter((product) => {
          return product.name.toLowerCase().includes(search.toLowerCase());
        }),
      );
    },
  };
};

export type PimProduct = {
  id: string;
  model: string;
  partNumber: string;
  variant: string;
  year: number;
  categoryId: string;
  categoryName: string;
  categoryPath: string;
  make: string;
  name: string;
  makeId: string;
  isDeleted: boolean;
};

const mockData: PimProduct[] = [
  {
    id: "1",
    model: "Model A",
    partNumber: "PN001",
    variant: "Variant 1",
    year: 2020,
    categoryId: "Category 1",
    categoryName: "Category 1",
    categoryPath:
      "General Equipment > Material Handling > Forklifts & Material Handling > Telehandlers > Telescopic Telehandlers",
    make: "Make A",
    name: "2024, Genie, GTH-844, Telehandler 8,000 - 9,000 lbs, 40’ - 44’ Reach",
    makeId: "Make 1",
    isDeleted: false,
  },
  {
    id: "2",
    model: "Model B",
    partNumber: "PN002",
    variant: "Variant 2",
    year: 2021,
    categoryId: "Category 2",
    categoryName: "Category 2",
    categoryPath: "Ditching > Trenchers > Walk-Behind Trenchers",
    make: "Make B",
    name: "Actually Not A Telehandler But A Trencher",
    makeId: "Make 2",
    isDeleted: false,
  },
  {
    id: "3",
    model: "Model C",
    partNumber: "PN003",
    variant: "Variant 3",
    year: 2019,
    categoryId: "Category 3",
    categoryName: "Category 3",
    categoryPath: "Magic Equipment > Telehandlers > Telehandling Telehandlers",
    make: "Make C",
    name: "Some Other Random Telehandler",
    makeId: "Make 3",
    isDeleted: false,
  },
];

export default mockData;

export const useManufacturers = () => {
  return {
    data: mockFilters.manufacturers,
  };
};

export const useCategories = () => {
  return {
    data: mockFilters.categories,
  };
};

export const useModels = () => {
  return {
    data: mockFilters.models,
  };
};
