import { EuiBetaBadge } from "@equipmentshare/ds2";

import { useTreePickerPalette } from "../../../../hooks";

export type SingleSelectProps = {
  handleSelect: () => void;
  hasFocus: boolean;
  hasSelectedDescendants?: boolean;
  label: string;
  nodeColor?: string;
  selected: boolean;
  selectedDescendantIcon?: string;
};

export const SingleSelect = ({
  handleSelect,
  hasFocus,
  hasSelectedDescendants,
  label,
  nodeColor,
  selected,
  selectedDescendantIcon,
}: SingleSelectProps) => {
  const { treePickerPalette } = useTreePickerPalette();

  const selectionIndicatorColor =
    nodeColor || treePickerPalette.singleSelectRadioChecked;

  const getSelectionIndicatorIcon = () => {
    if (selected) {
      return hasFocus ? "cross" : "check";
    }

    if (hasSelectedDescendants) {
      return selectedDescendantIcon || "check";
    }

    return hasFocus ? "check" : "empty";
  };

  const getSelectionIndicatorStyle = () => {
    if (hasFocus) {
      return {
        backgroundColor: treePickerPalette.singleSelectRadioFocus,
        color: treePickerPalette.singleSelectRadioText,
      };
    } else if (selected || hasSelectedDescendants) {
      return {
        backgroundColor: selectionIndicatorColor,
        color: treePickerPalette.singleSelectRadioText,
      };
    } else {
      return {
        backgroundColor: "inherit",
        color: "inherit",
      };
    }
  };

  const selectionIndicatorIcon = getSelectionIndicatorIcon();
  const selectionIndicatorStyle = getSelectionIndicatorStyle();
  const testId = "treepicker-node-single-select";

  return (
    <div data-testid={testId}>
      <EuiBetaBadge
        aria-label={`Select ${label}`}
        data-testid={`${testId}--${selectionIndicatorIcon}`}
        iconType={selectionIndicatorIcon}
        label={label}
        onClick={handleSelect}
        size="s"
        style={{
          ...selectionIndicatorStyle,
          cursor: "pointer",
        }}
      />
    </div>
  );
};
