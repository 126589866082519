import {
  TreePickerActionType,
  TreePickerReducerAction,
  TreePickerReducerState,
} from "../../types";
import { getDescendantIds, getParentIds } from "../../utils";

export const treePickerReducer = (
  state: TreePickerReducerState,
  action: TreePickerReducerAction,
): TreePickerReducerState => {
  switch (action.type) {
    case TreePickerActionType.SelectNode: {
      const { node, nodeMap } = action.payload;

      // If no node is provided, reset state
      if (!node) {
        return { selectedNodeIds: [], nodeStates: {} };
      }

      const newSelectedNodes = [node.id];
      const parentIds = getParentIds(node, nodeMap);

      // Reset state before applying new selection
      const updatedNodeStates: TreePickerReducerState["nodeStates"] = {};

      // Mark parents as having selected descendants
      parentIds.forEach((id) => {
        updatedNodeStates[id] = {
          selected: false,
          hasSelectedDescendants: true,
        };
      });

      // Update active Node state
      updatedNodeStates[node.id] = {
        selected: true,
        hasSelectedDescendants: false,
      };

      return {
        selectedNodeIds: newSelectedNodes,
        nodeStates: updatedNodeStates,
      };
    }

    case TreePickerActionType.DeselectNode: {
      const { node, nodeMap } = action.payload;
      const newSelectedNodes: string[] = [];
      const parentIds = getParentIds(node, nodeMap);
      const descendantIds = getDescendantIds(node);

      const updatedNodeStates = { ...state.nodeStates };

      // Deselect node
      updatedNodeStates[node.id] = {
        selected: false,
        hasSelectedDescendants: false,
      };

      // Deselect all descendants
      descendantIds.forEach((id) => {
        updatedNodeStates[id] = {
          selected: false,
          hasSelectedDescendants: false,
        };
      });

      // Update parent states
      parentIds.forEach((id) => {
        updatedNodeStates[id] = {
          selected: false,
          hasSelectedDescendants: false,
        };
      });

      return {
        selectedNodeIds: newSelectedNodes,
        nodeStates: updatedNodeStates,
      };
    }

    default:
      return state;
  }
};
