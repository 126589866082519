import { type MeQuery } from "@fleet-configuration/client";

import type { SmartComponentDefaults } from "../../../types";

export type GetMeProps = SmartComponentDefaults<MeQuery | null>;

export const GetMe = (props: GetMeProps) => {
  if (props.loading) {
    return <div>Loading...</div>;
  }

  if (props.error) {
    return <div>Error: {props.error.message}</div>;
  }

  const userData = props.data?.me ?? null;
  console.log("userData: ", userData);

  return (
    <div>
      <h1>Get Me Data:</h1>
      <p>{JSON.stringify(userData, null, 2)}</p>
    </div>
  );
};
