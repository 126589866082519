import { useEffect, useState } from "react";

import type { TreePickerNode } from "../../components/tree-picker/types";
import { filterTreePickerNodes } from "../../components/tree-picker/utils";

export type UseTreePickerFilteredNodesProps = {
  activeQuery: string | undefined;
  data: TreePickerNode[];
};

export const useTreePickerFilteredNodes = ({
  activeQuery,
  data,
}: UseTreePickerFilteredNodesProps): TreePickerNode[] => {
  const [filteredData, setFilteredData] = useState<TreePickerNode[]>(() =>
    activeQuery ? filterTreePickerNodes({ nodes: data, activeQuery }) : data,
  );

  useEffect(() => {
    const newData = activeQuery
      ? filterTreePickerNodes({ nodes: data, activeQuery })
      : data;
    setFilteredData(newData);
  }, [activeQuery, data]);

  return filteredData;
};
