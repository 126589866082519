import { EuiForm, EuiText } from "@equipmentshare/ds2";
import { useCategories, useManufacturers } from "@fleet-configuration/client";
import { useState } from "react";
import {
  Control,
  Controller,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";

import { FlyoutForm } from "../../flyout-form";
import { ComboBoxField, TextField, YearField } from "../../form-fields";
import { AddManufacturerFlyout } from "../add-manufacturer-flyout";

export type AddProductFlyoutProps = {
  isVisible: boolean;
  onCancel: () => void;
  onAddProduct: () => void;
};

type AddProductFormFields = {
  category: string;
  manufacturer: string;
  model: string;
  productDisplayName: string;
  variant: string;
  year: string;
};

const REQUIRED_FIELD_ERROR = "This field is required.";

export const AddProductFlyout = ({
  isVisible,
  onCancel,
  onAddProduct,
}: AddProductFlyoutProps) => {
  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      category: "",
      manufacturer: "",
      model: "",
      year: "",
      variant: "",
      productDisplayName: "",
    } as AddProductFormFields,
  });

  const [isAddManufacturerFlyoutVisible, setIsAddManufacturerFlyoutVisible] =
    useState<boolean>(false);

  const [newManufacturerName, setNewManufacturerName] = useState<string>("");

  const { data: manufacturers } = useManufacturers();
  const { data: categories } = useCategories();

  const onSubmit: SubmitHandler<AddProductFormFields> = async (data) => {
    console.log(data);
  };

  const handleSave = async () => {
    onAddProduct();
    await handleSubmit(async (data) => {
      try {
        await onSubmit(data);
      } catch (e: any) {
        console.log("********************");
        console.log(e);
        console.log("********************");
        return;
      }
    })();
  };

  const defaultFieldRules = {
    required: { value: true, message: REQUIRED_FIELD_ERROR },
  };

  const FormBody = () => {
    return (
      <EuiForm
        data-testid="add-product-form"
        onSubmit={() => handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="category"
          render={({
            field: { value, onChange },
            fieldState: { error, invalid },
          }) => {
            return (
              <ComboBoxField
                errorMessage={error?.message}
                isInvalid={invalid}
                isRequired
                label="Category"
                onChange={onChange}
                options={categories?.map((category) => ({
                  label: category.name,
                  value: category.id, // TODO: need to make sure that the correct ID value gets sent to the backend, but the correct string value gets set in productDisplayName. Or can I just map the name to the id before submitting to the BE?
                }))}
                placeholder="Select category"
                value={value}
              />
            );
          }}
          rules={defaultFieldRules}
        />
        <Controller
          control={control}
          name="manufacturer"
          render={({
            field: { value, onChange },
            fieldState: { error, invalid },
          }) => {
            return (
              <ComboBoxField
                customOptionText={`Click or press Enter to create new manufacturer "{searchValue}"`}
                errorMessage={error?.message}
                isInvalid={invalid}
                isRequired
                label="Manufacturer"
                onChange={onChange}
                onCreateOption={(option) => {
                  setNewManufacturerName(option);
                  setIsAddManufacturerFlyoutVisible(true);
                }}
                onHoverInfoText={`The Original Equipment Manufacturer. Use "Custom Built" for an asset that is custom made.`}
                options={manufacturers?.map((manufacturer) => ({
                  label: manufacturer.name,
                  value: manufacturer.id,
                }))}
                placeholder="Select manufacturer"
                testId="manufacturer-combo-box"
                value={value}
              />
            );
          }}
          rules={defaultFieldRules}
        />
        <Controller
          control={control}
          name="model"
          render={({
            field: { value, onChange },
            fieldState: { error, invalid },
          }) => {
            return (
              <TextField
                errorMessage={error?.message}
                isInvalid={invalid}
                isRequired
                label="Model"
                onChange={onChange}
                onHoverInfoText="Model is the manufacturer's identification number for the product, e.g. GTH-10054."
                placeholder="Enter model"
                value={value}
              />
            );
          }}
          rules={defaultFieldRules}
        />
        <Controller
          control={control}
          name="year"
          render={({
            field: { value, onChange },
            fieldState: { error, invalid },
          }) => {
            return (
              <YearField
                errorMessage={error?.message}
                isInvalid={invalid}
                isRequired
                label="Year"
                onChange={onChange}
                placeholder="Select year"
                value={value}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="variant"
          render={({
            field: { value, onChange },
            fieldState: { error, invalid },
          }) => {
            return (
              <TextField
                errorMessage={error?.message}
                isInvalid={invalid}
                label="Variant"
                onChange={onChange}
                onHoverInfoText={
                  <EuiText>
                    {"Variant is a version of a model, e.g. "}
                    <strong>King Ranch</strong>
                    {", of the Ford F-150 series."}
                  </EuiText>
                }
                placeholder="Enter variant"
                value={value}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="productDisplayName"
          render={() => <ProductDisplayName control={control} />}
        />
      </EuiForm>
    );
  };

  return (
    <>
      <FlyoutForm
        flyoutBody={<FormBody />}
        handleClose={() => {}}
        headerText="Add Product"
        isVisible={isVisible}
        primaryAction={{
          action: handleSave,
          label: "Add Product",
        }}
        subHeader="Enter the product details."
        tertiaryAction={{
          action: onCancel,
          label: "Cancel",
        }}
      />
      {newManufacturerName && isAddManufacturerFlyoutVisible && (
        <AddManufacturerFlyout
          isVisible={isAddManufacturerFlyoutVisible}
          manufacturerName={newManufacturerName}
          onCancel={() => {
            setIsAddManufacturerFlyoutVisible(false);
          }}
          onSave={() => {
            setIsAddManufacturerFlyoutVisible(false);
          }}
        />
      )}
    </>
  );
};

type ProductDisplayNameProps = {
  control: Control<AddProductFormFields>;
};

const ProductDisplayName = ({ control }: ProductDisplayNameProps) => {
  const [year, manufacturer, model, variant, category] = useWatch({
    control,
    name: ["year", "manufacturer", "model", "variant", "category"],
  });

  const productDisplayName = [year, manufacturer, model, variant, category]
    .map((value) => value.trim())
    .filter((value) => !!value)
    .join(" ");

  return (
    <TextField
      helpText="This field is populated by the information from the fields above it."
      isDisabled
      label="Product Display Name"
      onChange={() => {}}
      placeholder="Auto-generated"
      testId="product-display-name"
      value={productDisplayName}
    />
  );
};
