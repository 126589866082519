import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from "@equipmentshare/ds2";
import { ReactNode } from "react";

import { Footer, FooterProps } from "./footer";

import { RequiredAsterisk } from "../form-fields/form-field-row/form-row-label";

export type FlyoutFormProps = {
  hideOverlay?: boolean;
  flyoutBody: ReactNode;
  headerText: string;
  isVisible: boolean;
  hasRequiredFields?: boolean;
  handleClose: () => void;
  subHeader?: ReactNode;
} & FooterProps;

export const FlyoutForm = ({
  isVisible,
  handleClose,
  flyoutBody,
  headerText,
  primaryAction,
  secondaryAction,
  subHeader,
  tertiaryAction,
  hasRequiredFields = false,
  hideOverlay,
}: FlyoutFormProps) => {
  const extraStyles = hideOverlay ? " background: transparent;" : "";

  return isVisible ? (
    <EuiFlyout
      data-testid="flyout"
      maskProps={{ style: `inset-block-start: 0px;${extraStyles}` }}
      onClose={handleClose}
      ownFocus
      size="m"
      style={{ maxWidth: "400px" }}
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2 data-testid="header">{headerText}</h2>
        </EuiTitle>
        {subHeader && (
          <div data-testid="subheader">
            <EuiSpacer size="s" />
            {subHeader}
          </div>
        )}
        {hasRequiredFields && (
          <>
            <EuiSpacer size="s" />
            <EuiText>
              <p>
                {"Fields marked with an asterisk ("}
                <RequiredAsterisk />
                {") are required."}
              </p>
            </EuiText>
          </>
        )}
      </EuiFlyoutHeader>
      <EuiFlyoutBody>{flyoutBody}</EuiFlyoutBody>

      <Footer
        primaryAction={primaryAction}
        secondaryAction={secondaryAction}
        tertiaryAction={tertiaryAction}
      />
    </EuiFlyout>
  ) : null;
};
