import { type HierarchyNode } from "./types";

export const MOCK_RESOURCE_MAP_DATA: HierarchyNode[] = [
  {
    id: "6617bd5851db35b6807da4b6",
    value: "Equipmentshare",
    children: [
      {
        id: "6626b0c7c56f4d90d3afaf15",
        parentId: "6617bd5851db35b6807da4b6",
        value: "Pacific",
        path: "Equipmentshare > Pacific",
        children: [
          {
            id: "6626b19cc56f4d90d3afaf2a",
            parentId: "6626b0c7c56f4d90d3afaf15",
            value: "1-1",
            path: "Equipmentshare > Pacific > 1-1",
            children: [
              {
                id: "6626b512c56f4d90d3afafda",
                parentId: "6626b19cc56f4d90d3afaf2a",
                value: "Pasco - WA",
                path: "Equipmentshare > Pacific > 1-1 > Pasco - WA",
                children: [],
              },
              {
                id: "6626b512c56f4d90d3afafdc",
                parentId: "6626b19cc56f4d90d3afaf2a",
                value: "Seattle - WA",
                path: "Equipmentshare > Pacific > 1-1 > Seattle - WA",
                children: [],
              },
              {
                id: "6626b512c56f4d90d3afafdb",
                parentId: "6626b19cc56f4d90d3afaf2a",
                value: "Portland - OR",
                path: "Equipmentshare > Pacific > 1-1 > Portland - OR",
                children: [
                  {
                    id: "6627d16af334aefed698ca64",
                    parentId: "6626b512c56f4d90d3afafdb",
                    value: "Tooling Solutions",
                    path: "Equipmentshare > Pacific > 1-1 > Portland - OR > Tooling Solutions",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: "6626b19cc56f4d90d3afaf2b",
            parentId: "6626b0c7c56f4d90d3afaf15",
            value: "1-2",
            path: "Equipmentshare > Pacific > 1-2",
            children: [
              {
                id: "6626b536c56f4d90d3afafe5",
                parentId: "6626b19cc56f4d90d3afaf2b",
                value: "Martinez - CA",
                path: "Equipmentshare > Pacific > 1-2 > Martinez - CA",
                children: [
                  {
                    id: "6627d16af334aefed698ca66",
                    parentId: "6626b536c56f4d90d3afafe5",
                    value: "Tooling Solutions",
                    path: "Equipmentshare > Pacific > 1-2 > Martinez - CA > Tooling Solutions",
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: "6626b19cc56f4d90d3afaf2c",
            parentId: "6626b0c7c56f4d90d3afaf15",
            value: "1-3",
            path: "Equipmentshare > Pacific > 1-3",
            children: [
              {
                id: "6626b523c56f4d90d3afafe1",
                parentId: "6626b19cc56f4d90d3afaf2c",
                value: "Lancaster - CA",
                path: "Equipmentshare > Pacific > 1-3 > Lancaster - CA",
                children: [],
              },
              {
                id: "6626b523c56f4d90d3afafdf",
                parentId: "6626b19cc56f4d90d3afaf2c",
                value: "Bakersfield - CA",
                path: "Equipmentshare > Pacific > 1-3 > Bakersfield - CA",
                children: [
                  {
                    id: "6627d788f334aefed698cac8",
                    parentId: "6626b523c56f4d90d3afafdf",
                    value: "Core Solutions",
                    path: "Equipmentshare > Pacific > 1-3 > Bakersfield - CA > Core Solutions",
                    children: [],
                  },
                ],
              },
              {
                id: "6626b523c56f4d90d3afafe3",
                parentId: "6626b19cc56f4d90d3afaf2c",
                value: "San Diego - CA",
                path: "Equipmentshare > Pacific > 1-3 > San Diego - CA",
                children: [],
              },
              {
                id: "6626b523c56f4d90d3afafe2",
                parentId: "6626b19cc56f4d90d3afaf2c",
                value: "Long Beach - CA",
                path: "Equipmentshare > Pacific > 1-3 > Long Beach - CA",
                children: [
                  {
                    id: "6627d16af334aefed698ca65",
                    parentId: "6626b523c56f4d90d3afafe2",
                    value: "Tooling Solutions",
                    path: "Equipmentshare > Pacific > 1-3 > Long Beach - CA > Tooling Solutions",
                    children: [],
                  },
                ],
              },
              {
                id: "6626b523c56f4d90d3afafe0",
                parentId: "6626b19cc56f4d90d3afaf2c",
                value: "Fontana - CA",
                path: "Equipmentshare > Pacific > 1-3 > Fontana - CA",
                children: [],
              },
            ],
          },
          {
            id: "6626b19cc56f4d90d3afaf2d",
            parentId: "6626b0c7c56f4d90d3afaf15",
            value: "1-4",
            path: "Equipmentshare > Pacific > 1-4",
            children: [
              {
                id: "6626b4fbc56f4d90d3afafd6",
                parentId: "6626b19cc56f4d90d3afaf2d",
                value: "San Diego - CA",
                path: "Equipmentshare > Pacific > 1-4 > San Diego - CA",
                children: [
                  {
                    id: "6627d0acf334aefed698ca15",
                    parentId: "6626b4fbc56f4d90d3afafd6",
                    value: "Advanced Solutions",
                    path: "Equipmentshare > Pacific > 1-4 > San Diego - CA > Advanced Solutions",
                    children: [],
                  },
                ],
              },
              {
                id: "6626b4fbc56f4d90d3afafd4",
                parentId: "6626b19cc56f4d90d3afaf2d",
                value: "Portland - OR",
                path: "Equipmentshare > Pacific > 1-4 > Portland - OR",
                children: [
                  {
                    id: "6627d0acf334aefed698ca13",
                    parentId: "6626b4fbc56f4d90d3afafd4",
                    value: "Advanced Solutions",
                    path: "Equipmentshare > Pacific > 1-4 > Portland - OR > Advanced Solutions",
                    children: [],
                  },
                ],
              },
              {
                id: "6626b4fbc56f4d90d3afafd7",
                parentId: "6626b19cc56f4d90d3afaf2d",
                value: "Seattle - WA",
                path: "Equipmentshare > Pacific > 1-4 > Seattle - WA",
                children: [
                  {
                    id: "6627d0acf334aefed698ca16",
                    parentId: "6626b4fbc56f4d90d3afafd7",
                    value: "Advanced Solutions",
                    path: "Equipmentshare > Pacific > 1-4 > Seattle - WA > Advanced Solutions",
                    children: [],
                  },
                ],
              },
              {
                id: "6626b4fbc56f4d90d3afafd8",
                parentId: "6626b19cc56f4d90d3afaf2d",
                value: "Spokane - WA",
                path: "Equipmentshare > Pacific > 1-4 > Spokane - WA",
                children: [
                  {
                    id: "6627d0acf334aefed698ca17",
                    parentId: "6626b4fbc56f4d90d3afafd8",
                    value: "Advanced Solutions",
                    path: "Equipmentshare > Pacific > 1-4 > Spokane - WA > Advanced Solutions",
                    children: [],
                  },
                ],
              },
              {
                id: "6626b4fbc56f4d90d3afafd2",
                parentId: "6626b19cc56f4d90d3afaf2d",
                value: "Colton - CA",
                path: "Equipmentshare > Pacific > 1-4 > Colton - CA",
                children: [
                  {
                    id: "6627d01df334aefed698c9d7",
                    parentId: "6626b4fbc56f4d90d3afafd2",
                    value: "Advanced Solutions",
                    path: "Equipmentshare > Pacific > 1-4 > Colton - CA > Advanced Solutions",
                    children: [],
                  },
                ],
              },
              {
                id: "6626b4fbc56f4d90d3afafd1",
                parentId: "6626b19cc56f4d90d3afaf2d",
                value: "Bakersfield - CA",
                path: "Equipmentshare > Pacific > 1-4 > Bakersfield - CA",
                children: [
                  {
                    id: "6627cf32f334aefed698c989",
                    parentId: "6626b4fbc56f4d90d3afafd1",
                    value: "Advanced Solutions",
                    path: "Equipmentshare > Pacific > 1-4 > Bakersfield - CA > Advanced Solutions",
                    children: [],
                  },
                ],
              },
              {
                id: "6626b4fbc56f4d90d3afafd5",
                parentId: "6626b19cc56f4d90d3afaf2d",
                value: "Sacramento - CA",
                path: "Equipmentshare > Pacific > 1-4 > Sacramento - CA",
                children: [
                  {
                    id: "6627d0acf334aefed698ca14",
                    parentId: "6626b4fbc56f4d90d3afafd5",
                    value: "Advanced Solutions",
                    path: "Equipmentshare > Pacific > 1-4 > Sacramento - CA > Advanced Solutions",
                    children: [],
                  },
                ],
              },
              {
                id: "6626b4fbc56f4d90d3afafd3",
                parentId: "6626b19cc56f4d90d3afaf2d",
                value: "Newark - CA",
                path: "Equipmentshare > Pacific > 1-4 > Newark - CA",
                children: [
                  {
                    id: "6627d01df334aefed698c9d8",
                    parentId: "6626b4fbc56f4d90d3afafd3",
                    value: "Advanced Solutions",
                    path: "Equipmentshare > Pacific > 1-4 > Newark - CA > Advanced Solutions",
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
