import { EuiFilterGroup, EuiSelectableOption } from "@equipmentshare/ds2";
import { ApiFilters, ProductFilterState } from "@fleet-configuration/client";

import { FilterButton } from "../../filter-button";

type ProductFilterProps = {
  filters: ApiFilters;
  onChangeFilter: (
    filterType: keyof ProductFilterState,
  ) => (options: EuiSelectableOption[]) => void;
  onClearFilter: (filterType: keyof ProductFilterState) => void;
};

export const ProductFilters = ({
  filters,
  onChangeFilter,
  onClearFilter,
}: ProductFilterProps) => {
  return (
    <EuiFilterGroup fullWidth>
      <FilterButton
        filterOptions={filters.categories.map((filter) => {
          return {
            label: filter.name,
            key: filter.id,
          };
        })}
        label="Category"
        onChange={onChangeFilter("categories")}
        onClearAll={() => onClearFilter("categories")}
      />
      <FilterButton
        filterOptions={filters.manufacturers.map((filter) => {
          return {
            label: filter.name,
            key: filter.id,
          };
        })}
        label="Manufacturer"
        onChange={onChangeFilter("manufacturers")}
        onClearAll={() => onClearFilter("manufacturers")}
      />
      <FilterButton
        filterOptions={filters.models.map((filter) => {
          return {
            label: filter.name,
            key: filter.id,
          };
        })}
        label="Model"
        onChange={onChangeFilter("models")}
        onClearAll={() => onClearFilter("models")}
      />
      <FilterButton
        filterOptions={filters.years.map((filter) => {
          return {
            label: filter.name,
            key: filter.id,
          };
        })}
        label="Year"
        onChange={onChangeFilter("years")}
        onClearAll={() => onClearFilter("years")}
      />
    </EuiFilterGroup>
  );
};
