import { euiPaletteColorBlindBehindText } from "@equipmentshare/ds2";

export type ReportingDesignationColorMap = {
  rd1: string;
  rd2: string;
  rd3: string;
  rd4: string;
  rd5: string;
  rd6: string;
  rd7: string;
};

export type UseReportingDesignationColorMap = {
  reportingDesignationColorMap: ReportingDesignationColorMap;
};

export const useReportingDesignationColorMap =
  (): UseReportingDesignationColorMap => {
    const euiColorVis = euiPaletteColorBlindBehindText({ rotations: 1 });

    const reportingDesignationColorMap: ReportingDesignationColorMap = {
      rd1: euiColorVis[5], // Maps to #f1d86f
      rd2: euiColorVis[2], // Maps to #ee789d
      rd3: euiColorVis[3], // Maps to #a987d1
      rd4: euiColorVis[4], // Maps to #e4a6c7
      rd5: euiColorVis[7], // Maps to #f5a35c
      rd6: euiColorVis[8], // Maps to #c47c6c
      rd7: euiColorVis[6], // Maps to #d2c0a0
    };

    return {
      reportingDesignationColorMap,
    };
  };
