import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useReducer, useRef } from "react";

import { treePickerReducer } from "./treePickerNodeSelect.reducer";

import { useTreePickerExpandCollapse } from "../../../../hooks/useTreePickerExpandCollapse";
import { useTreePickerFilteredNodes } from "../../../../hooks/useTreePickerFilteredNodes";
import { Node } from "../../node";
import {
  type NodePayload,
  NodeSelectionType,
  TreePickerActionType,
  type TreePickerNode,
} from "../../types";
import { buildNodeMap, isNodeSelectable } from "../../utils";
import { NoResults } from "../no-results/NoResults";

export type TreePickerNodeSelectProps = {
  activeQuery?: string;
  data: TreePickerNode[];
  nodeColor?: string;
  onChange?: (payload: TreePickerNode["id"][]) => void;
  selectedDescendantIcon?: string;
  selectedNodeId?: TreePickerNode["id"];
  style?: React.CSSProperties;
  toggleExpandCollapseAll?: boolean;
};

export const TreePickerNodeSelect = ({
  activeQuery,
  data,
  nodeColor,
  onChange,
  selectedDescendantIcon,
  selectedNodeId,
  style,
  toggleExpandCollapseAll,
}: TreePickerNodeSelectProps) => {
  const hasMounted = useRef(false);
  const prevSelectedNodeRef = useRef<TreePickerNode["id"] | null>();

  const filteredData = useTreePickerFilteredNodes({ activeQuery, data });

  const { expandedNodes, handleNodeExpand, handleNodeCollapse } =
    useTreePickerExpandCollapse({
      data: filteredData,
      activeQuery,
      toggleExpandCollapseAll,
    });

  // Build a node map for quick lookup
  const nodeMap = buildNodeMap(filteredData);

  const [state, dispatch] = useReducer(treePickerReducer, {
    selectedNodeIds: selectedNodeId ? [selectedNodeId] : [],
    nodeStates: {},
  });

  /**
   * Node Selection
   */

  useEffect(() => {
    // Update selected nodes when the selectedNodeId prop changes
    if (
      selectedNodeId &&
      selectedNodeId.length > 0 &&
      selectedNodeId !== prevSelectedNodeRef.current
    ) {
      const selectedNodeFromMap = nodeMap[selectedNodeId];

      if (selectedNodeFromMap) {
        dispatch({
          type: TreePickerActionType.SelectNode,
          payload: { node: selectedNodeFromMap, nodeMap, selected: true },
        });
      }
    }

    // Track ref to prevent infinite loop since we are updating a dependency (selectedNodeIds)
    prevSelectedNodeRef.current = selectedNodeId;
  }, [nodeMap, selectedNodeId]);

  useEffect(() => {
    // Ensure that the onChange callback is called when nodes are selected
    if (hasMounted.current) {
      onChange?.(state.selectedNodeIds || []);
    } else {
      hasMounted.current = true;
      return;
    }
  }, [onChange, state.selectedNodeIds]);

  const handleNodeSelect = useCallback(
    (payload: NodePayload) => {
      dispatch({
        type: TreePickerActionType.SelectNode,
        payload: { ...payload, nodeMap },
      });
    },
    [nodeMap],
  );

  const handleNodeDeselect = useCallback(
    (payload: NodePayload) => {
      dispatch({
        type: TreePickerActionType.DeselectNode,
        payload: { ...payload, nodeMap },
      });
    },
    [nodeMap],
  );

  /**
   * Renderers
   */

  // Recursively render nodes
  const renderNodes = (nodes: TreePickerNode[], level: number) => {
    return nodes.map((node) => {
      const nodeState = state.nodeStates[node.id] || {
        selected: false,
        hasSelectedDescendants: false,
      };
      return (
        <div key={node.id}>
          <Node
            activeQuery={activeQuery}
            displayNodeSelection={true}
            hasSelectedDescendants={nodeState.hasSelectedDescendants}
            isExpanded={expandedNodes.includes(node.id)}
            isSelectable={isNodeSelectable(node, false)}
            level={level}
            node={node}
            nodeColor={nodeColor}
            onCollapse={handleNodeCollapse}
            onDeselect={handleNodeDeselect}
            onExpand={handleNodeExpand}
            onSelect={handleNodeSelect}
            selected={nodeState.selected}
            selectedDescendantIcon={selectedDescendantIcon}
            selectionType={NodeSelectionType.Single}
          />
          <AnimatePresence>
            {expandedNodes.includes(node.id) && node.children.length > 0 && (
              <motion.div
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0, overflow: "hidden" }}
                initial={{ opacity: 0, height: 0, overflow: "hidden" }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                {renderNodes(node.children, level + 1)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      );
    });
  };

  return (
    <div data-testid="treepicker" style={style}>
      {filteredData.length === 0 ? (
        <NoResults activeQuery={activeQuery} />
      ) : (
        renderNodes(filteredData, 0)
      )}
    </div>
  );
};
