import {
  EuiButtonIcon,
  EuiHighlight,
  EuiNotificationBadge,
  EuiText,
} from "@equipmentshare/ds2";
import { useEffect, useState } from "react";

import { MultiSelect } from "./multi-select";
import { SingleSelect } from "./single-select";

import { useTreePickerPalette } from "../../../hooks";
import {
  type NodePayload,
  NodeSelectionType,
  type TreePickerNode,
} from "../types";

export type NodeProps<TNode extends TreePickerNode = TreePickerNode> = {
  activeQuery?: string;
  count?: number;
  displayNodeSelection?: boolean;
  hasSelectedDescendants?: boolean;
  isExpanded?: boolean;
  isSelectable?: boolean;
  level: number;
  node: TNode;
  nodeColor?: string;
  onCollapse: (payload: NodePayload<TNode>) => void;
  onDeselect: (payload: NodePayload<TNode>) => void;
  onExpand: (payload: NodePayload<TNode>) => void;
  onSelect: (payload: NodePayload<TNode>) => void;
  selected: boolean;
  selectedDescendantIcon?: string;
  selectionType?: NodeSelectionType;
};

export const NODE_HEIGHT = 26;
export const OFFSET_WIDTH = 24;

export const Node = <TNode extends TreePickerNode = TreePickerNode>({
  activeQuery = "",
  count,
  displayNodeSelection = true,
  hasSelectedDescendants,
  isExpanded = false,
  isSelectable = true,
  level,
  node,
  nodeColor,
  onCollapse,
  onDeselect,
  onExpand,
  onSelect,
  selected,
  selectedDescendantIcon,
  selectionType = NodeSelectionType.Single,
}: NodeProps<TNode>) => {
  const { treePickerPalette } = useTreePickerPalette();

  const [hasFocus, setHasFocus] = useState(false);
  const [nodeExpanded, setNodeExpanded] = useState(isExpanded);

  // Ensure the nodeExpanded state is in sync with the isExpanded prop
  useEffect(() => {
    setNodeExpanded(isExpanded);
  }, [isExpanded]);

  const hasChildNodes = node.children.length > 0;

  const showSelect = () => {
    if (
      !isSelectable ||
      (hasChildNodes && selectionType === NodeSelectionType.SingleLeafOnly)
    ) {
      return false;
    }

    return displayNodeSelection;
  };

  const setFocus = () => setHasFocus(true);
  const unsetFocus = () => setHasFocus(false);

  const handleExpand = () => {
    setNodeExpanded(true);
    onExpand({ node, selected });
  };

  const handleCollapse = () => {
    setNodeExpanded(false);
    onCollapse({ node, selected });
  };

  const handleSelect = () => {
    if (
      !isSelectable ||
      (selectionType === NodeSelectionType.SingleLeafOnly && hasChildNodes)
    )
      return;
    if (selected) {
      onDeselect({ node, selected: false });
    }
    if (!selected) {
      onSelect({ node, selected: true });
    }
  };

  const MultiSelectProps = {
    handleSelect: handleSelect,
    id: node.id,
    indeterminate: hasSelectedDescendants && displayNodeSelection,
    label: node.value,
    selected: selected,
  };

  const SingleSelectProps = {
    handleSelect: handleSelect,
    hasFocus: hasFocus,
    hasSelectedDescendants: hasSelectedDescendants,
    label: node.value,
    nodeColor: nodeColor,
    selected: selected,
    selectedDescendantIcon: selectedDescendantIcon ?? undefined,
  };

  const SingleSelectLeafOnlyProps = {
    ...SingleSelectProps,
    hasSelectedDescendants: false,
    selectedDescendantIcon: undefined,
  };

  return (
    <div
      aria-label={node.value}
      data-testid="treepicker-node"
      onMouseEnter={setFocus}
      onMouseLeave={unsetFocus}
      style={{
        display: "flex",
        height: NODE_HEIGHT,
        backgroundColor: hasFocus ? treePickerPalette.nodeHoverBg : "unset",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <div
        data-testid="treepicker-node-indention"
        style={{
          width: level * OFFSET_WIDTH,
          minWidth: level * OFFSET_WIDTH,
          height: NODE_HEIGHT,
        }}
      />
      {hasChildNodes && (
        <div
          style={{
            minWidth: OFFSET_WIDTH,
            width: OFFSET_WIDTH,
          }}
        >
          <div
            data-testid="treepicker-node-arrow"
            style={{
              minWidth: OFFSET_WIDTH,
              width: OFFSET_WIDTH,
            }}
          >
            {nodeExpanded ? (
              <EuiButtonIcon
                aria-label="Collapse"
                data-testid="treepicker-node-arrow-collapse"
                display="empty"
                iconSize="m"
                iconType="arrowDown"
                onClick={() => {
                  handleCollapse();
                }}
              />
            ) : (
              <EuiButtonIcon
                aria-label="Expand"
                data-testid="treepicker-node-arrow-expand"
                display="empty"
                iconSize="m"
                iconType="arrowRight"
                onClick={() => {
                  handleExpand();
                }}
              />
            )}
          </div>
        </div>
      )}
      {showSelect() ? (
        <div
          data-testid="treepicker-node-selection"
          style={{
            marginLeft:
              selectionType !== NodeSelectionType.SingleLeafOnly &&
              !hasChildNodes
                ? OFFSET_WIDTH
                : 0,
            minWidth: OFFSET_WIDTH,
            width: OFFSET_WIDTH,
          }}
        >
          {selectionType === NodeSelectionType.Single && (
            <SingleSelect {...SingleSelectProps} />
          )}
          {selectionType === NodeSelectionType.SingleLeafOnly && (
            <SingleSelect {...SingleSelectLeafOnlyProps} />
          )}
          {selectionType === NodeSelectionType.Multi && (
            <MultiSelect {...MultiSelectProps} />
          )}
        </div>
      ) : null}
      <EuiText
        color="subdued"
        data-testid="treepicker-node-text"
        onClick={handleSelect}
        size="m"
        style={{
          flex: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <EuiHighlight search={activeQuery}>{node.value}</EuiHighlight>
      </EuiText>
      {count && (
        <EuiNotificationBadge
          color={selected ? "accent" : "subdued"}
          data-testid="treepicker-node-count"
          size="m"
        >
          {count.toLocaleString()}
        </EuiNotificationBadge>
      )}
    </div>
  );
};
