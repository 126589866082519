import { EuiCheckbox } from "@equipmentshare/ds2";

import { TreePickerNode } from "../../types";

export type MultiSelectProps = {
  handleSelect: () => void;
  id: TreePickerNode["id"];
  indeterminate?: boolean;
  label: string;
  selected: boolean;
};

export const MultiSelect = ({
  handleSelect,
  id,
  indeterminate = false,
  label,
  selected,
}: MultiSelectProps) => {
  const testId = "treepicker-node-multi-select";
  return (
    <div data-testid={testId}>
      <EuiCheckbox
        aria-label={`Select ${label}`}
        checked={selected}
        data-testid={`${testId}-checkbox`}
        id={id}
        indeterminate={indeterminate}
        onChange={handleSelect}
      />
    </div>
  );
};
