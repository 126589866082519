import { EuiForm } from "@equipmentshare/ds2";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FlyoutForm } from "../../flyout-form";
import { TextField } from "../../form-fields";

type AddManufacturerFormFields = {
  manufacturer: string;
};

export type AddManufacturerFlyoutProps = {
  isVisible: boolean;
  onCancel: () => void;
  onSave: () => void;
  manufacturerName?: string;
};

const REQUIRED_FIELD_ERROR = "This field is required.";

export const AddManufacturerFlyout: React.FC<AddManufacturerFlyoutProps> = ({
  isVisible,
  onCancel,
  onSave,
  manufacturerName,
}) => {
  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: {
      manufacturer: manufacturerName || "",
    } as AddManufacturerFormFields,
  });

  const onSubmit: SubmitHandler<AddManufacturerFormFields> = async (data) => {
    console.log(data);
  };

  const handleSave = async () => {
    onSave();
    await handleSubmit(async (data) => {
      try {
        await onSubmit(data);
      } catch (e: any) {
        console.log("********************");
        console.log(e);
        console.log("********************");
        return;
      }
    })();
  };

  const FormBody = () => (
    <EuiForm
      data-testid="add-manufacturer-form"
      onSubmit={() => handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name="manufacturer"
        render={({
          field: { value, onChange },
          fieldState: { error, invalid },
        }) => {
          return (
            <TextField
              errorMessage={error?.message}
              isInvalid={invalid}
              isRequired
              label="Manufacturer"
              onChange={onChange}
              placeholder="Enter manufacturer"
              value={value}
            />
          );
        }}
        rules={{ required: { value: true, message: REQUIRED_FIELD_ERROR } }}
      />
    </EuiForm>
  );

  return (
    <FlyoutForm
      flyoutBody={<FormBody />}
      handleClose={onCancel}
      headerText="Add Manufacturer"
      hideOverlay
      isVisible={isVisible}
      primaryAction={{
        label: "Add Manufacturer",
        action: handleSave,
      }}
      subHeader="Enter the Original Equipment Manufacturer of the product."
      tertiaryAction={{
        label: "Cancel",
        action: onCancel,
      }}
    />
  );
};
