/**
 * Node Types and Interfaces for the Tree Picker that are generic and can be used
 * across different implementations of the Tree Picker.
 */

/**
 * Defines the basic structure for a node in the Tree Picker.
 */
export interface DefaultNode {
  id: string;
  parentId: string | null;
  path: string;
  value: string;
}

/**
 * Extends DefaultNode to include children nodes.
 */
export interface BaseNode<T extends BaseNode<T> = TreePickerNode>
  extends DefaultNode {
  children: T[];
}

/**
 * A specialized node type with a numeric count property.
 */
export interface NodeWithCount extends BaseNode {
  count: number;
}

/**
 * Represents the Root Node in a Resource Map, serving as the identifier for the
 * top-level resource.
 */
export interface HierarchyNode {
  id: string;
  value: string;
  children: ResourceNode[];
}

/**
 * A specialized node type for Resource Maps.
 */
export interface ResourceNode extends BaseNode<ResourceNode> {}

/**
 * A specialized node type for PIM Categories.
 */
export interface PimCategoryNode extends NodeWithCount {
  hasProducts: boolean;
}

/**
 * A union type representing the possible node types used in the Tree Picker.
 */
export type TreePickerNode = NodeWithCount | PimCategoryNode | ResourceNode;

/**
 * Enumeration of the available node selection types in the Tree Picker.
 */
export enum NodeSelectionType {
  Single = "SINGLE",
  SingleLeafOnly = "SINGLE_LEAF_ONLY",
  Multi = "MULTI",
}

/**
 * Represents the payload for node actions, containing the node and its selection status.
 */
export interface NodePayload<TNode extends TreePickerNode = TreePickerNode> {
  node: TNode;
  selected: boolean;
}

/**
 * Reducer Types
 */

/**
 * Represents the state of an individual node in the reducer.
 */
export interface NodeReducerState {
  selected: boolean;
  hasSelectedDescendants: boolean;
}

/**
 * Represents the overall state of the Tree Picker reducer, including the list of
 * selected node IDs and the state for each node.
 */
export interface TreePickerReducerState {
  selectedNodeIds: string[];
  nodeStates: Record<string, NodeReducerState>;
}

/**
 * Enumeration of action types for the Tree Picker reducer.
 */
export enum TreePickerActionType {
  SelectNode = "SELECT_NODE",
  DeselectNode = "DESELECT_NODE",
}

/**
 * Represents the actions dispatched to the Tree Picker reducer for selecting or
 * deselecting nodes.
 */
export type TreePickerReducerAction =
  | {
      type: TreePickerActionType.SelectNode;
      payload: NodePayload & { nodeMap: Record<string, TreePickerNode> };
    }
  | {
      type: TreePickerActionType.DeselectNode;
      payload: NodePayload & { nodeMap: Record<string, TreePickerNode> };
    };
