import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiGlobalToastList,
  EuiGlobalToastListToast,
  htmlIdGenerator,
} from "@equipmentshare/ds2";
import React from "react";

export type ToastConfig = Omit<EuiGlobalToastListToast, "id">;

type ShowCallbackType = (toast: ToastConfig) => void;
type ShowWithActionCallbackType = (toast: ToastWithAction) => void;
export type ShowErrorCallbackType = (toast: Omit<ToastConfig, "color">) => void;
type RemoveCallbackType = (toast: EuiGlobalToastListToast) => void;

type ContextType = {
  showToast: ShowCallbackType;
  showToastWithAction: ShowWithActionCallbackType;
  showErrorToast: ShowErrorCallbackType;
};

export type ToastWithAction = Omit<ToastConfig, "color"> & {
  actionText: string;
  action: () => void;
};

const defaultContext = {
  showToast: () => {},
  showToastWithAction: () => {},
  showErrorToast: () => {},
};
const ToastContext = React.createContext<ContextType>(defaultContext);

const useToastContext = () => React.useContext(ToastContext);

const ToastProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = React.useState<EuiGlobalToastListToast[]>([]);
  const showToast: ShowCallbackType = (toastToShow) => {
    setToasts((oldToasts) => [
      ...oldToasts,
      { ...toastToShow, id: htmlIdGenerator()() },
    ]);
  };

  const showErrorToast: ShowCallbackType = (toastToShow) => {
    setToasts((oldToasts) => [
      ...oldToasts,
      { ...toastToShow, color: "danger", id: htmlIdGenerator()() },
    ]);
  };

  const showToastWithAction: ShowWithActionCallbackType = (toastToShow) => {
    const { text, actionText, action } = toastToShow;
    setToasts((oldToasts) => [
      ...oldToasts,
      {
        ...toastToShow,
        text: (
          <>
            <p>{text}</p>
            <EuiFlexGroup gutterSize="s" justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <EuiButton color="success" onClick={action} size="s">
                  {actionText}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        ),
        color: "success",
        id: htmlIdGenerator()(),
      },
    ]);
  };

  const removeToast: RemoveCallbackType = (toastToRemove) => {
    setToasts((oldToasts) =>
      oldToasts.filter((toast) => toast.id !== toastToRemove.id),
    );
  };

  return (
    <ToastContext.Provider
      value={{ showToast, showToastWithAction, showErrorToast }}
    >
      {children}
      <EuiGlobalToastList
        css={{
          position: "fixed",
          bottom: 20,
          right: "150px",
          width: "320px",
          zIndex: 10000,
        }}
        dismissToast={removeToast}
        toastLifeTimeMs={10000}
        toasts={toasts}
      />
    </ToastContext.Provider>
  );
};

export { ToastProvider, useToastContext };
export default ToastContext;
