import { type ApolloClient } from "@apollo/client";

import { getApolloClient, GetApolloClientOpts } from "./getApolloClient";

let apolloClientInstance: ApolloClient<any> | null = null;

export const getApolloClientSingleton = (opts: GetApolloClientOpts) => {
  if (!apolloClientInstance) {
    apolloClientInstance = getApolloClient(opts);
  }
  return apolloClientInstance;
};

export const unsetApolloClientSingleton = () => {
  apolloClientInstance = null;
};
