import { PimCategoryNode } from "./types";

export const MOCK_PIM_CATEGORY_DATA: PimCategoryNode[] = [
  {
    id: "1",
    parentId: null,
    path: "Equipment",
    value: "Equipment",
    count: 120,
    hasProducts: true,
    children: [
      {
        id: "1-1",
        parentId: "1",
        path: "Equipment > Heavy Machinery",
        value: "Heavy Machinery",
        count: 60,
        hasProducts: false,
        children: [
          {
            id: "1-1-1",
            parentId: "1-1",
            path: "Equipment > Heavy Machinery > Bulldozers",
            value: "Bulldozers",
            count: 20,
            hasProducts: true,
            children: [],
          },
          {
            id: "1-1-2",
            parentId: "1-1",
            path: "Equipment > Heavy Machinery > Cranes",
            value: "Cranes",
            count: 40,
            hasProducts: true,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: "2",
    parentId: null,
    path: "Aerial Work Platforms",
    value: "Aerial Work Platforms",
    count: 80,
    hasProducts: true,
    children: [
      {
        id: "2-1",
        parentId: "2",
        path: "Aerial Work Platforms > Scissor Lifts",
        value: "Scissor Lifts",
        count: 50,
        hasProducts: true,
        children: [],
      },
      {
        id: "2-2",
        parentId: "2",
        path: "Aerial Work Platforms > Boom Lifts",
        value: "Boom Lifts",
        count: 30,
        hasProducts: true,
        children: [],
      },
    ],
  },
  {
    id: "3",
    parentId: null,
    path: "Material Handling",
    value: "Material Handling",
    count: 95,
    hasProducts: false,
    children: [
      {
        id: "3-1",
        parentId: "3",
        path: "Material Handling > Forklifts",
        value: "Forklifts",
        count: 50,
        hasProducts: true,
        children: [],
      },
      {
        id: "3-2",
        parentId: "3",
        path: "Material Handling > Pallet Jacks",
        value: "Pallet Jacks",
        count: 45,
        hasProducts: true,
        children: [],
      },
    ],
  },
  {
    id: "4",
    parentId: null,
    path: "Earthmoving Equipment",
    value: "Earthmoving Equipment",
    count: 110,
    hasProducts: false,
    children: [
      {
        id: "4-1",
        parentId: "4",
        path: "Earthmoving Equipment > Excavators",
        value: "Excavators",
        count: 70,
        hasProducts: true,
        children: [],
      },
      {
        id: "4-2",
        parentId: "4",
        path: "Earthmoving Equipment > Loaders",
        value: "Loaders",
        count: 40,
        hasProducts: true,
        children: [],
      },
    ],
  },
  {
    id: "5",
    parentId: null,
    path: "Attachments",
    value: "Attachments",
    count: 65,
    hasProducts: false,
    children: [
      {
        id: "5-1",
        parentId: "5",
        path: "Attachments > Buckets",
        value: "Buckets",
        count: 35,
        hasProducts: true,
        children: [],
      },
      {
        id: "5-2",
        parentId: "5",
        path: "Attachments > Grapples",
        value: "Grapples",
        count: 30,
        hasProducts: true,
        children: [],
      },
    ],
  },
  {
    id: "6",
    parentId: null,
    path: "Tools",
    value: "Tools",
    count: 85,
    hasProducts: false,
    children: [
      {
        id: "6-1",
        parentId: "6",
        path: "Tools > Power Tools",
        value: "Power Tools",
        count: 50,
        hasProducts: false,
        children: [
          {
            id: "6-1-1",
            parentId: "6-1",
            path: "Tools > Power Tools > Drills",
            value: "Drills",
            count: 25,
            hasProducts: true,
            children: [],
          },
          {
            id: "6-1-2",
            parentId: "6-1",
            path: "Tools > Power Tools > Saws",
            value: "Saws",
            count: 25,
            hasProducts: true,
            children: [],
          },
        ],
      },
      {
        id: "6-2",
        parentId: "6",
        path: "Tools > Hand Tools",
        value: "Hand Tools",
        count: 35,
        hasProducts: true,
        children: [
          {
            id: "6-2-2",
            parentId: "6-2",
            path: "Tools > Hand Tools > Screwdrivers",
            value: "Screwdrivers",
            count: 25,
            hasProducts: true,
            children: [],
          },
        ],
      },
    ],
  },
];
