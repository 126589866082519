import { useEuiBackgroundColor, useEuiTheme } from "@equipmentshare/ds2";

export type TreePickerPalette = {
  nodeHoverBg: string;
  singleSelectRadioText: string;
  singleSelectRadioFocus: string;
  singleSelectRadioChecked: string;
};

export type UseTreePickerPalette = {
  treePickerPalette: TreePickerPalette;
};

export const useTreePickerPalette = (): UseTreePickerPalette => {
  const { euiTheme } = useEuiTheme();

  return {
    treePickerPalette: {
      nodeHoverBg: useEuiBackgroundColor("primary"), // Maps to #e6f1fa
      singleSelectRadioText: useEuiBackgroundColor("plain"), // Maps to #FFF
      singleSelectRadioFocus: euiTheme.colors.subduedText, // Maps to #646a77
      singleSelectRadioChecked: euiTheme.colors.primaryText, // Maps to #006bb8
    },
  };
};
