import { EuiText } from "@equipmentshare/ds2";

export type NoResultsProps = {
  activeQuery?: string;
};

export const NoResults = ({ activeQuery }: NoResultsProps) => {
  let noResultsText = "No results found";
  if (activeQuery && activeQuery.length > 0) {
    noResultsText = `No results found for '${activeQuery}'`;
  }

  return (
    <EuiText color="default" data-testid="treepicker-no-results" size="s">
      <strong>{noResultsText}</strong>
    </EuiText>
  );
};
