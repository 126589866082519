import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiText,
  useEuiBackgroundColor,
  useEuiTheme,
} from "@equipmentshare/ds2";
import { AddCircleLineIcon } from "@equipmentshare/ds2-icons";
import { PimProduct } from "@fleet-configuration/client";
import { LIGHT_BORDER } from "@fleet-configuration/utils";

type ProductDisplayProps = {
  product: PimProduct;
  searchTerm?: string;
};

export const ProductDisplay = ({
  product,
  searchTerm,
}: ProductDisplayProps) => {
  const { euiTheme } = useEuiTheme();

  return (
    <>
      <EuiHighlight
        css={{
          fontWeight: "bold",
          color: searchTerm
            ? euiTheme.colors.disabledText
            : euiTheme.colors.text,
        }}
        highlightAll
        search={searchTerm ?? ""}
      >
        {product.name}
      </EuiHighlight>
      <EuiSpacer size="xs" />
      <EuiHighlight
        css={{
          color: euiTheme.colors.disabledText,
        }}
        highlightAll
        search={searchTerm ?? ""}
      >
        {product.categoryPath}
      </EuiHighlight>
    </>
  );
};

type SearchResultProps = ProductDisplayProps & {
  onSelect: (product: PimProduct) => void;
};

export const SearchResult = ({
  product,
  onSelect,
  searchTerm,
}: SearchResultProps) => {
  return (
    <EuiFlexGroup
      css={{
        display: "flex",
        alignItems: "center",
        ":hover, :focus": {
          backgroundColor: useEuiBackgroundColor("primary"),
        },
      }}
      gutterSize="xs"
      responsive={false}
      style={{ borderBottom: `${LIGHT_BORDER}`, padding: "16px" }}
    >
      <EuiFlexItem>
        <ProductDisplay product={product} searchTerm={searchTerm} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          aria-label="Select product"
          color="text"
          data-testid={`select-product-${product.id}`}
          iconType={AddCircleLineIcon}
          onClick={() => onSelect(product)}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export type SelectedProductProps = {
  selectedProduct: PimProduct;
  onEdit: (product: PimProduct) => void;
};

export const SelectedProduct = ({
  selectedProduct,
  onEdit,
}: SelectedProductProps) => {
  const { euiTheme } = useEuiTheme();
  return (
    <EuiPanel color="primary">
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem>
          <EuiText color={euiTheme.colors.disabledText} size="xs">
            <strong>MATCHED PRODUCT</strong>
          </EuiText>
          <EuiSpacer size="xs" />
          <ProductDisplay product={selectedProduct} />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ paddingRight: "50px" }}>
          <EuiLink onClick={() => onEdit(selectedProduct)}>Edit</EuiLink>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};
